body {
    font-kerning: auto;
    backface-visibility: visible !important;

    display: flex;
    min-height: 100vh;

    flex-direction: column;
    font-size: 12pt;
}

.content-wrap {
    flex: 1;
}

.jumbotron{
    background: $brand-primary;

    p,
    li,
    h1, h2, h3, h4, h5, h6,
    a {
        color: #fff;

        &.underlined {
            &:after {
                border-bottom: 1px solid #fff;
            }
        }
    }

    p,
    li,
    a {
        font-weight: 300;
    }

    h1 {
        font-size: ($font-size-h1 * 1.2);
    }

    .input-lg {
        height: 56px;
    }

    .primary-bg {
        background: #fff;

        color: $text-color;

        box-shadow: 0 4px 4px rgba(0,0,0,0.4);

        padding: 3em 0;

        h1, h2, h3 {
            color: darken($brand-primary, 5%);
        }

        p {
            color: $text-color;
        }
    }

    .btn {
        padding: 1em 2em;
    }

    &.head {
        padding: 3em 0 0;
        margin: 0;
    }

    &.cutoff {
        padding-top: 0;
        padding-bottom: 0;
    }

    &.subpage-cta {
        margin-top: 2em;
    }
}

.two-columns {
    @media (min-width: $screen-sm-min) {
        column-count: 2;
        column-gap: 3em;
    }
}

.btn-success {
    margin-top: 2px;
    margin-bottom: 1px;

    border-bottom: 6px solid darken($brand-success, 12%);
    box-shadow: 0 5px 2px rgba(0,0,0,0.3), 0 8px 25px rgba(0,0,0,0.5);
    border-radius: 0 0 2px 2px;

    &:hover {
        margin-top: 2px;
        margin-bottom: 1px;

        border-bottom: 6px solid darken($brand-success, 24%);
    }

    &:active {
        margin-top: 8px;
        margin-bottom: 4px;

        border-bottom: 0 solid darken($brand-success, 12%);
        box-shadow: 0 1px 8px rgba(0,0,0,0.1), 0 2px 25px rgba(0,0,0,0.2);
        border-radius: 0;
    }
}

h1 {
    margin-top: 1em;
    margin-bottom: 1em;
}

h1, h2, h3, h4, h5, h6 {
    strong {
        font-weight: 900;
    }

    small {
        color: #fff;
    }

    &.underlined {
        margin-bottom: 1em;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            display: inline-block;
            height: 1em;
            width: 50%;
            margin-left: 25%;

            border-bottom: 1px solid $text-color;

            margin-top: 0.5em;
        }
    }
}

h3, h4, h5, h6 {
    font-family: $font-family-sans-serif;
}

ul,
h1, h2, h3, h4, h5, h6,
p {
    a {
        border-bottom: 1px dashed $brand-primary;

        &:hover {
            text-decoration: none;

            border-bottom: 1px solid $brand-primary;
        }
    }
}

.heading-shadow {
    text-shadow: 0 1px 0 lighten($brand-primary, 1%),
                    0 2px 0 darken($brand-primary, 1%)/*,
                    0 2px 0 #c9c9c9,
                    0 3px 0 #bbb,
                    0 4px 0 #b9b9b9,
                    0 5px 0 #aaa*/,
                    0 6px 1px rgba(0,0,0,.1),
                    0 0 5px rgba(0,0,0,.1),
                    0 1px 3px rgba(0,0,0,.3),
                    0 3px 5px rgba(0,0,0,.2),
                    0 5px 10px rgba(0,0,0,.25),
                    0 10px 10px rgba(0,0,0,.2),
                    0 20px 20px rgba(0,0,0,.15);
}

.stack {
    background: $brand-primary;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
    margin: 26px auto 0;
    max-width: 550px;
    min-height: 300px;
    padding: 24px;
    position: relative;
    width: 80%;

    color: #fff;

    &:before,
    &:after {
        content: "";
        height: 98%;
        position: absolute;
        width: 100%;
        z-index: -1;
    }

    &:before {
        background: darken($brand-primary, 8%);
        box-shadow: 0 0 8px rgba(0,0,0,0.2);
        left: -5px;
        top: 4px;
        transform: rotate(-2.5deg);
    }

    &:after {
        background: darken($brand-primary, 14%);
        box-shadow: 0 0 3px rgba(0,0,0,0.2);
        right: -3px;
        top: 1px;
        transform: rotate(1.4deg);
    }

    a {
        color: #fff;

        border-bottom: 1px dashed #fff;

        &:hover {
            text-decoration: none;

            border-bottom: 1px solid #fff;
        }
    }
}

.img-card {
    border: 5px solid #fff;
    padding: 5px;
}

br {
    clear: both;

    &.half {
        content: " ";
        display: block;

        margin-top: ($line-height-base * 0.25em);
    }
    &.quarter {
        content: " ";
        display: block;

        margin-top: ($line-height-base * 0.125em);
    }
}

hr {
    &.huge {
        margin-top: 3em;
        margin-bottom: 2em;

        border-top: none;
        border-bottom: 6px solid lighten($brand-primary, 60%);
    }
}

.faded {
    opacity: 0.75;
}

.btn {
    transition: border 100ms ease-in-out 100ms, all 200ms ease-in-out;
}

.faux-3d-cover {
    border-bottom: 1px solid darken($brand-primary, 14%);

    box-shadow: 0 8px 24px rgba(0,0,0,0.4);
}

h3.large {
    font-size: 28px;
}

h4.large {
    font-size: 24px;
}

.censored {
    color: #000;
    background-color: #000;
}

/* Remove firefox dotted line */
a,
a:active,
a:focus,
input,
input:active,
input:focus,
button,
button:active,
button:focus,
select,
select:active,
select:focus,
.bootstrap-select .btn,
.bootstrap-select .btn:active,
.bootstrap-select .btn:focus {
    outline: 0 !important;
}

/* Remove webkit outline glow */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
