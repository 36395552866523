$gray-base:             #000;
$gray-darker:           lighten($gray-base, 13.5%);
$gray-dark:             lighten($gray-base, 20%);
$gray:                  lighten($gray-base, 33.5%);
$gray-light:            lighten($gray-base, 46.7%);
$gray-lighter:          lighten($gray-base, 93.5%);

$brand-primary:         #3f2b4f;
$brand-success:         #4d9b6b;
$brand-info:            #5bc0de;
$brand-warning:         #f0ad4e;
$brand-danger:          #d9534f;

$border-radius-base:    0;
$border-radius-large:   0;
$border-radius-small:   0;

$jumbotron-color:       #fff;
$jumbotron-bg:          $brand-primary;

$font-family-sans-serif:  "PT Sans", Helvetica, Arial, sans-serif !default;
$font-family-serif:       "Raleway", Helvetica, Arial, sans-serif !default;

$headings-font-family:    $font-family-serif;
$headings-font-weight:    700;
