@font-face {
    font-family: 'icomoon';
    src:    url('../fonts/icomoon.eot?6uwons');
    src:    url('../fonts/icomoon.eot?6uwons#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?6uwons') format('truetype'),
    url('../fonts/icomoon.woff?6uwons') format('woff'),
    url('../fonts/icomoon.svg?6uwons#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-tea:before {
    content: "\e903";
}
.icon-newsletter:before {
    content: "\e902";
}
.icon-book:before {
    content: "\e900";
}
.icon-circle-dollar:before {
    content: "\e901";
}
.icon-file-pdf-o:before {
    content: "\f1c1";
}

.icon-bump {
    font-size: 1.25em;
}

.icon-2x {
    font-size: 2em;
}

.icon-3x {
    font-size: 3em;
}

.icon-4x {
    font-size: 4em;
}

.icon-6x {
    font-size: 6em;
}

.icon-gumroad {
    background: transparent url("../img/gumroad.svg") repeat scroll 0% 0% / cover;
    display: inline-block;
    height: 24px;
    position: relative;
    width: 22px;

    vertical-align: bottom;
}
